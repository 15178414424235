import { localeList, defaultLocale } from './ConfigData.json';
import MetaTagsData from './MetaTags';
import LoadGRMGlobal from './LoadGRM';
import jquery from 'jquery';


let localeVals = localeList.find(o => o.locale === defaultLocale),
    windowOriginURL = window.location.origin,
    windowHrefURL = window.location.href,
    getLocale = defaultLocale,
    getIsTOS = localeVals.isTOS,
    getIsCDC = localeVals.isCDC,
    getIsShipping = localeVals.isShipping,
    getIsSplitter = localeVals.isSplitter,
    showReceiptField = localeVals.isShowReceiptField,
    getNpr = localeVals.isNpr;

const checkLocale = () => {
    var localeFrmUrl;
    if(windowHrefURL == windowOriginURL + '/'){
        localeFrmUrl = defaultLocale;
    }else{
        localeFrmUrl = (windowHrefURL.split(windowOriginURL + '/')[1]).split('/')[0].toLowerCase();
    }

    localeList.forEach(function (item) {
        if (localeFrmUrl.toLowerCase() == item.locale.toLowerCase()) {
            getLocale = item.locale;
            getIsTOS = item.isTOS;
            getIsCDC = item.isCDC;
            getIsShipping = item.isShipping;
            getIsSplitter = item.isSplitter;
            showReceiptField = item.isShowReceiptField;
            getNpr = item.isNpr;
        }
    });
}
checkLocale();

const getUA = () => {
    var ua = navigator.userAgent,
        browserStr = /Safari/i.test(ua) && !(/Chrome/i.test(ua)) ? 'safari ' : '',
        browserStr = /Android/i.test(ua) ? browserStr + 'android ' : browserStr + '',
        browserStr = /iPhone/i.test(ua) ? browserStr + 'iphone ' : browserStr + '',
        browserStr = /iPad/i.test(ua) ? browserStr + 'ipad ' : browserStr + '',
        browserStr = /Mac/i.test(ua) ? browserStr + 'mac' : browserStr + '';
    document.querySelector('html').className = browserStr;
}
getUA();

export const pageScroll = () => {
    window.scrollTo(0, 0);
    setTimeout(function () {
        $('html, body').animate({
            scrollTop: $('.container').offset().top - 100
        }, 1000);
    }, 1000);
}

export const relativeURL = process.env.PUBLIC_URL;
export const $ = jquery;
export const locale = getLocale;
export const isTOS = getIsTOS;
export const isCDC = getIsCDC;
export const isShipping = getIsShipping;
export const isSplitter = getIsSplitter;
export const isShowReceiptField = showReceiptField;
export const isNpr = getNpr;
export const MetaTags = MetaTagsData;
export const LoadGRM = LoadGRMGlobal;