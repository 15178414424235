export const getLocalData = (key) => {
    var value = localStorage.getItem(key);
    if (!value) { return; }
    // assume it is an object that has been stringified
    if (value[0] === "{" && key != 'servToken') {
        value = JSON.parse(value);
    }
    return value;
};
export const setLocalData = (key, value) => {
    if (!key || !value) { return; }
    if (typeof value === "object") {
        value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
};
export const removeLocalData = (key) => {
    if (!key) { return; }
    localStorage.removeItem(key);
};
export const getSessionData = (key) => {
    var value = sessionStorage.getItem(key);
    if (!value) { return; }
    // assume it is an object that has been stringified
    if (value[0] === "{" && key != 'servToken') {
        value = JSON.parse(value);
    }
    return value;
};
export const setSessionData = (key, value) => {
    if (!key || !value) { return; }
    if (typeof value === "object") {
        value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
};
export const removeSessionData = (key) => {
    if (!key) { return; }
    sessionStorage.removeItem(key);
};
