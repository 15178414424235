import React, { useContext } from 'react';
import { MetaTags, LoadGRM } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';

const Recoverpassword = () => {
    const { translations } = useContext(JsonContext);

    document.querySelector('body').setAttribute('pagename', 'Recoverpassword');

    LoadGRM('reset', function () { });

    return (
        <>
            <MetaTags
                title={translations.CampaignName }
                description={translations.MetaContent}
                keywords=''
            />
            <section className="recoverpassword-page login-page">
                <h3>{translations.ResetPasswordHeader}</h3>
                <div id="reg-container" className="register-form"></div>
            </section>
        </>
    )
}

export default Recoverpassword;