import React, { useContext } from 'react';
import Button from '../Partials/Button';
import { MetaTags } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';

const Closed = ()=> {
    const { translations } = useContext(JsonContext);

    sessionStorage.removeItem('createPageState');
    document.querySelector('body').setAttribute('pagename', 'Closed');

    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_ClosedPageTitle + ' | ' + translations.CampaignName }
                description={translations.MetaContent}
                keywords=''
            />
            <section className="closed-page">
                <h1 className="uppercase">{translations.EndPromoTitle}</h1>
                <Button buttonType = 'closed'/>
            </section>
        </>
    )
}

export default Closed;