import React, { useContext, useState } from 'react';
import Button from '../Partials/Button';
import { locale, LoadGRM, $, isCDC } from '../Utility/Global'
import JsonContext from '../Utility/JsonProvider';

const Login = () => {
    const { translations } = useContext(JsonContext);
    const [callOnce, setCallOnce] = useState(false);
    if (!callOnce) {
        LoadGRM('login', function () {
            if ($('.hiding').length > 0) {
                $('.hiding').addClass('hide');
            }
        });
        setCallOnce(true);
    }

    document.querySelector('body').setAttribute('pagename', 'Login');

    return (
        <>
            {!isCDC && <section className="login-page">
                <div className="title-content">
                    <div className="hiding" role="alert" >{translations.LoginPageHeading}</div>
                    <h1>{translations.LoginPageHeading}</h1>
                    <p dangerouslySetInnerHTML={{ __html:translations.LoginPageDescription}}></p>
                    <p dangerouslySetInnerHTML={{ __html:translations.LoginPageDescription1}}></p>
                    <p>{translations.LoginPageDescription2}</p>
                    <p className="tnclink">
                        <span><sup>▴</sup></span>
                        <a title={translations.CoreKelloggs_TncsApplyLinkTitle} href={'/' + locale + '/TERMS'} dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_TncApply }}></a>
                    </p>
                    <h2>{translations.LoginSectionHeading}</h2>
                    <p className="mndtry-desc" dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_RegisterPageMandatoryCopy }}></p>
                </div>
                <div id="reg-container" className="register-form"></div>
                <div className="divider">{translations.Or}</div>
                <Button buttonType='register'/>
            </section>}
            {isCDC && <section className="login-page">
               
                <div id="reg-container" className="register-form"></div>
               
            </section>}
        </>
    )
}

export default Login;