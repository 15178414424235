import React, { useContext } from 'react';
import { Redirect } from "react-router-dom";
import * as webStorageService from '../Utility/WebStorageFactory';
import { locale, MetaTags, pageScroll } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';

const Movie = () => {
    const { translations } = useContext(JsonContext);

    let pageState = webStorageService.getSessionData('createPageState');

    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Movie') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }

    document.querySelector('body').setAttribute('pagename', 'Movie');

    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_ChooseMoviePageTitle + ' | ' + translations.CampaignName }
                description={translations.MetaContent}
                keywords=''
            />
            <section className="movie-page">
                <h1>{translations.CoreKelloggs_ChooseMoviePageTitle}</h1>
                Movie Page
            </section>
        </>
    )
}

export default Movie;