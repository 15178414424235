import React, { useContext } from 'react';
import { locale, isShipping, MetaTags } from '../Utility/Global';
import * as webStorageService from '../Utility/WebStorageFactory';
import Login from './Login';
import Shipping from './Shipping';
import JsonContext from '../Utility/JsonProvider';

const Home = () => {
    const { translations } = useContext(JsonContext);
    webStorageService.removeSessionData('codes');
    webStorageService.removeSessionData('createPageState');
    webStorageService.removeSessionData('btnObj');
    webStorageService.removeSessionData('shippingBtnObj');
    webStorageService.removeSessionData('receiptObj');
    const _intervalG = setInterval(function () {
        logout();
    }, 300);
    function logout() {
        if (window.gigya != undefined) {
            window.gigya.accounts.logout();
            clearInterval(_intervalG);
        }
    }

    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_HomePageTitle + ' | ' + translations.CampaignName }
                description={translations.MetaContent}
                keywords=''
            />
            {
                isShipping ? <Shipping /> : <Login />
            }
        </>
    )
}

export default Home;