import React, { useContext } from 'react';
import { Redirect } from "react-router-dom";
import * as webStorageService from '../Utility/WebStorageFactory';
import { locale, MetaTags } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import dataLayers from '../Utility/DataLayers';
import Button from '../Partials/Button';
const Limit = () => {
    const { translations } = useContext(JsonContext);
    let pageState = webStorageService.getSessionData('createPageState');
        // pageState = 'Limit';
    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Limit') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }

    document.querySelector('body').setAttribute('pagename', 'Limit');

    let globalLimit = webStorageService.getSessionData('limitType'),
        // globalLimit = 'GlobalRedemptionLimit',
        limitPageHeading,
        limitPageDesc,
        limitPageDesc1;
    const _intervalG = setInterval(function () {
        logout();
    }, 300);
    function logout() {
        if (window.gigya != undefined) {
            window.gigya.accounts.logout();
            clearInterval(_intervalG);
        }
    }

    if (globalLimit == 'GlobalRedemptionLimit') {
        dataLayers().winLimit();
        limitPageHeading = translations.Sorry;
        limitPageDesc = translations.WinLimit;
    } else if (globalLimit == 'MaxUserAttemptLimit') {
        limitPageDesc = translations.CoreKelloggs_MaximumEntryLimit;
        limitPageDesc1 = translations.CoreKelloggs_MaximumEntryLimit_teamPringles;
    } else {
        dataLayers().entryLimit();
        limitPageHeading = translations.Sorry;
        limitPageDesc = translations.DailyLimit;
        limitPageDesc1 = translations.DailyLimitDesc;
    }
    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_LimitPageTitle + ' | ' + translations.CampaignName }
                description={translations.MetaContent}
                keywords=''
            />
            <section className="limit-page">
                {limitPageHeading && <h1>{limitPageHeading}</h1>}
                {limitPageDesc && <p role="alert" dangerouslySetInnerHTML={{ __html: limitPageDesc }}></p>}
                {limitPageDesc1 && <p role="alert" dangerouslySetInnerHTML={{ __html: limitPageDesc1 }}></p>}
                <div className="cta-container">
                    <Button />
                </div>
            </section>
        </>
    )
}

export default Limit;