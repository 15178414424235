import React, { useContext,useState } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";

import { locale } from './Components/Utility/Global';

import Header from './Components/Partials/Header';
import Footer from './Components/Partials/Footer';

import Closed from './Components/Screens/Closed';
import Comingsoon from './Components/Screens/Comingsoon';
import Splitter from './Components/Screens/Splitter';
import Home from './Components/Screens/Home';
import Register from './Components/Screens/Register';
import Forgotpassword from './Components/Screens/Forgotpassword';
import Recoverpassword from './Components/Screens/Recoverpassword';
import Batchcode from './Components/Screens/Batchcode';
import Movie from './Components/Screens/Movie';
import Wordsearch from './Components/Screens/Wordsearch';
import Puzzle from './Components/Screens/Puzzle';
import Thankyou from './Components/Screens/Thankyou';
import Limit from './Components/Screens/Limit';
import Error from './Components/Screens/Error';
import FAQ from './Components/Screens/FAQ';
import Terms from './Components/Screens/Terms';
import JsonContext from './Components/Utility/JsonProvider';
import './Components/Utility/vendor/criticalcom';
import  PromotionState from './Components/Utility/PromotionState';

import './Styles/celebrate2022.scss';
var $ = require('jquery');
var jQuery = require('jquery');

window.$ = $;
window.jQuery = $;

const App = () => {
  const { translations, settings } = useContext(JsonContext);
  const [loaded, setLoaded] = useState(false);
  const loadApp = (translations !== "" && translations !== undefined && settings !== "" && settings !== undefined) ? true : false;
  const faqTnc = (window.location.href.toLowerCase()).indexOf('faq') > -1 || (window.location.href.toLowerCase()).indexOf('terms') > -1;
  if(loadApp && !loaded){
    setLoaded(true);
   const {initPage} = PromotionState(settings,translations);
   initPage();
  sessionStorage.removeItem('btnObj');
  sessionStorage.removeItem('shippingBtnObj');
  sessionStorage.removeItem('receiptObj');
  }

  return (
    <>
      {loadApp && loaded &&
        <React.Fragment>
           <section className="main-wrapper">
          <Header />
          <main>
            <div className="container">
              <Switch>
                <Route exact path={'/' + locale + '/Closed'}>
                  <Closed />
                </Route>
                <Route exact path={'/' + locale + '/Comingsoon'}>
                  <Comingsoon />
                </Route>
                <Route exact path={'/ch'}>
                  <Splitter />
                </Route>
                <Route exact path={'/be'}>
                  <Splitter />
                </Route>
                <Route exact path={'/be/:uuid'}>
                  <Redirect to={'/' + locale} />
                </Route>
                <Route exact path={'/ch/:uuid'}>
                  <Redirect to={'/' + locale} />
                </Route>
                <Route exact path={'/' + locale + '/Home'}>
                  <Home />
                </Route>
                <Route exact path={'/' + locale + '/Register'}>
                  <Register />
                </Route>
                <Route exact path={'/' + locale + '/ForgotPassword'}>
                  <Forgotpassword />
                </Route>
                <Route exact path={'/' + locale + '/RecoverPassword'}>
                  <Recoverpassword />
                </Route>
                <Route exact path={'/' + locale + '/Batchcodeentry'}>
                  <Batchcode />
                </Route>
                <Route exact path={'/' + locale + '/Movie'}>
                  <Movie />
                </Route>
                <Route exact path={'/' + locale + '/Wordsearch/:uuid'}>
                  <Wordsearch />
                </Route>
                <Route exact path={'/' + locale + '/Puzzle/:uuid'}>
                  <Puzzle />
                </Route>
                <Route exact path={'/' + locale + '/Thankyou/:uuid'}>
                  <Thankyou />
                </Route>
                <Route exact path={'/' + locale + '/Limit'}>
                  <Limit />
                </Route>
                <Route exact path={'/' + locale + '/Error/:uuid'}>
                  <Error />
                </Route>
                <Route exact path={'/' + locale + '/FAQ'}>
                  <FAQ />
                </Route>
                <Route exact path={'/' + locale + '/Terms'}>
                  <Terms />
                </Route>
                <Redirect to={'/' + locale + '/Home'} />
              </Switch>
            </div>
          </main>
          </section>
          {
            locale != 'be' && locale != 'ch' && !faqTnc && <Footer />
          }
        </React.Fragment> 
    }
    </>
  );
}

export default App;