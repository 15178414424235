import React, { useContext } from 'react';
import { locale } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';

const Header = () => {
    const { translations } = useContext(JsonContext);
    
    const switchCountry = (e) => {
        e.preventDefault();
        window.location = "/" + e.target.value + "/Home";
    }
    return (
        <>
            <header id="Header">
                {/* <div className="swicth_country">
                    <label>Country: </label>
                    <select onChange={switchCountry}>
                        <option>Select Your Country</option>
                        {
                            localeList.map((data, index) => {
                                if (data.showInCountryDropDown) {
                                    return <option key={index} value={data.locale}>{data.countryName}</option>
                                }
                            })
                        }
                    </select>
                </div> */}
                <picture picturefill="true">
                    <source srcSet={'/content/img/header-visuals/celebrate2022-header-desktop-' + locale + '.png'} media="only screen and (min-width: 768px)" />
                    <source srcSet={'/content/img/header-visuals/celebrate2022-header-mobile-' + locale + '.png'} media="only screen and (max-width: 767px)" />
                    <img src={'/content/img/header-visuals/celebrate2022-header-mobile-' + locale + '.png'} alt={translations.HeaderAltText} />
                </picture>
            </header>
        </>
    )
}

export default Header;