import React, { useContext } from 'react';
import * as webStorageService from '../Utility/WebStorageFactory';
import { locale, MetaTags } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import dataLayers from '../Utility/DataLayers';

const Splitter = () => {
    const { translations } = useContext(JsonContext);

    webStorageService.removeSessionData('codes');
    webStorageService.removeSessionData('createPageState');

    document.querySelector('body').setAttribute('pagename', 'Splitter');
    return (
        <>
            <MetaTags
                title={translations.SplitterPageTitle + ' | ' + translations.CampaignName }
                description={translations.MetaContent}
                keywords=''
            />
            <section className="splitter-page">
                <h1 dangerouslySetInnerHTML={{ __html: translations.SplitterPageHeading }}></h1>
                
                {
                    locale == 'be' &&
                    <div className="content">
                        <a href="/fr_BE/Home" onClick={()=>dataLayers().selectSplitLocale('fr_BE')} className="button" title={translations.SplitterPage_frenchSite_ctaTitle} target="_self">
                            <span className="btn-copy">{translations.SplitterPageLink1}</span>
                        </a>
                        <a href="/nl_BE/Home" onClick={()=>dataLayers().selectSplitLocale('nl_BE')} className="button" title={translations.SplitterPage_dutchSite_ctaTitle} target="_self">
                            <span className="btn-copy">{translations.SplitterPageLink2}</span>
                        </a>
                    </div>
                }
                {
                    locale == 'ch' &&
                    <div className="content">
                        <a href="/fr_CH/Home" onClick={()=>dataLayers().selectSplitLocale('fr_CH')} className="button" title={translations.SplitterPage_frenchSite_ctaTitle} target="_self">
                            <span className="btn-copy">{translations.SplitterPageLink1}</span>
                        </a>
                        <a href="/de_CH/Home" onClick={()=>dataLayers().selectSplitLocale('de_CH')} className="button" title={translations.SplitterPage_germanSite_ctaTitle} target="_self">
                            <span className="btn-copy">{translations.SplitterPageLink2}</span>
                        </a>
                    </div>
                }
            </section>
        </>
    )
}

export default Splitter;