import React, { useState, createContext } from 'react';
import axios from 'axios';
import { locale } from './Global';

const JsonContext = createContext();
export const LoadJson = ({ children }) => {
    const [translations, setTranslations] = useState('');
    const [settings, setSettings] = useState('');
    let windowOriginURL = window.location.origin,
        jsonEnvUrl = (windowOriginURL.indexOf('stage.') >= 0 || windowOriginURL.indexOf('staging-') >= 0) ? 'Stage' : (windowOriginURL.indexOf('local.') >= 0 || windowOriginURL.indexOf('dev-') >= 0) ? 'Dev' : 'Prod';
    // Translations Call
    let translationsLoadFlag = (translations === '') ? true : false,
        translationsURL = (locale == 'be' || locale == 'ch') ? '/Content/mock/' + locale + '/translations.json' : '/Content/translations/LetsCelebrate2022_' + locale + '.json';
    if (translationsLoadFlag) {
        async function getData() {
            const res = await axios.get(translationsURL);
            setTranslations(res.data.Translations);
        }
        getData();
    }

    // Settings Call
    let settingsLoadFlag = (settings === '') ? true : false,
        settingsURL = (locale == 'be' || locale == 'ch') ? '/Content/mock/settings_splitter.json' : '/Content/settingsJson/values_' + jsonEnvUrl + '.json',
        settingsData;
    if (settingsLoadFlag) {
        async function getSettings() {
            const res = await axios.get(settingsURL);
            settingsData = res.data.filter(function (itm) {
                return itm.Locale == locale;
            });
            setSettings(settingsData[0]);
        }
        getSettings();
    }
    return <JsonContext.Provider value={{ translations: translations, settings: settings }}>{children}
    </JsonContext.Provider>
}

export default JsonContext;