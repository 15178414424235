import React, { useContext } from 'react';
import { Redirect, useLocation } from "react-router-dom";
import * as webStorageService from '../Utility/WebStorageFactory';
import { locale, MetaTags } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import dataLayers from '../Utility/DataLayers';
import Button from '../Partials/Button';

const Thankyou = () => {
    const { translations } = useContext(JsonContext);
    const location = useLocation();
    let pageState = webStorageService.getSessionData('createPageState');
   // pageState = 'Thankyou';

    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Thankyou') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }

   webStorageService.removeSessionData('createPageState');
   document.querySelector('body').setAttribute('pagename', 'Thankyou');

   var winnerCode = location.state.winner,
      // var winnerCode = 1,
       isExtendedPhase = location.state.isExtendedPhase;
       //  isExtendedPhase = false;
    var winCategory, WinPageDesc, WinPageHeading, WinPageSubHeading, WinPageDesc1, refCode, WinPageDesc2,refNumberCopy;
    if (isExtendedPhase) {
        winCategory = 'extended'
        WinPageHeading = translations.PrizeDrawThankyouHeading;
        WinPageDesc = translations.PrizeDrawThankyouDesc;
        WinPageDesc1 = translations.PrizeDrawThankyouDesc1;
        dataLayers().orderData(97);
    }


    var setThankyouContent = function () {
        switch (winnerCode) {
            case 0:
            case -16:
                winCategory = 'Lose';
                WinPageHeading = translations.Sorry;
                WinPageDesc = translations.NotAWinnerPageHeading;
                WinPageDesc1 = translations.NotAWinnerPageDesc;
                break;
            case 1:
                winCategory = 'Samsung Sero';
                WinPageHeading = translations.Tier1SmartTv_heading +" " + translations.Tier1SmartTv_heading1;
                WinPageDesc = translations.Tier1SmartTv_desc;
                WinPageDesc1 = translations.Tier1SmartTv_desc1;
                refNumberCopy = translations.ReferenceNumberCopy
                refCode = location.state.refCode;
                // refCode = 'XXXXX';
                WinPageDesc2 = translations.Tier1SmartTv_desc2
                break;
            case 97:
                winCategory = 'Crossword';
                WinPageHeading = translations.ThankYouPageHeading;
                WinPageDesc = translations.ThankYouPageDescription;
                WinPageDesc1 = translations.ThankYouPageDescription1;
                break;
            default:
                // $location.path(lang + '/Home');
                break;
        }
    }
    if (!isExtendedPhase) {
        setThankyouContent();
    }
    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_ThankyouPageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
            <section className="thankyou-page">
                {WinPageHeading && <h1 dangerouslySetInnerHTML={{ __html: WinPageHeading }}></h1>}
                <div role="alert" className="hiding" >{WinPageHeading}</div>
                {WinPageDesc && <p dangerouslySetInnerHTML={{ __html: WinPageDesc }}></p>}
                {WinPageDesc1 && <p dangerouslySetInnerHTML={{ __html: WinPageDesc1 }}></p>}
                {refNumberCopy && <p className="ref-copy" dangerouslySetInnerHTML={{ __html: refNumberCopy }}></p>}
                {refCode && <p className="ref-copy refCode" dangerouslySetInnerHTML={{ __html: refCode }}></p>}
                {WinPageDesc2 && <p dangerouslySetInnerHTML={{ __html: WinPageDesc2 }}></p>}
                <div className="cta-container">
                    <Button/>
                </div>
            </section>
        </>
    )
}

export default Thankyou;