import React, { useState, useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { locale, isShowReceiptField, MetaTags, $, pageScroll } from '../Utility/Global';
import '../Utility/vendor/lightboxv2.min';
import * as API from '../Utility/ApiCalls';
import * as webStorageService from '../Utility/WebStorageFactory';
import JsonContext from '../Utility/JsonProvider';
import ErrorProvider from '../Utility/ErrorProvider';
import dataLayers from '../Utility/DataLayers';

const Batchcode = () => {
    const history = useHistory();
    const { HandleError } = ErrorProvider();
    const _intervalG = setInterval(function () {
        logout();
    }, 300);
    function logout() {
        if (window.gigya != undefined) {
            window.gigya.accounts.logout();
            clearInterval(_intervalG);
        }
    }

    const handleRoute = (path, obj) => {
        if (path.toLowerCase().indexOf('thankyou') > -1) {
            webStorageService.setSessionData('createPageState', 'Thankyou');
        } else if (path.toLowerCase().indexOf('puzzle') > -1) {
            webStorageService.setSessionData('createPageState', 'Puzzle');
        }
        else if (path.toLowerCase().indexOf('limit') > -1) {
            webStorageService.setSessionData('createPageState', 'Limit');
        }
        history.push({ pathname: path, state: obj });
        pageScroll();
    }
    const [codesArrObj, setCodesArrObj] = useState([{
        'objId': '',
        'id': '',
        'errors': '',
        'labels': '',
        'value': '',
        'uploadId': '',
        'receiptNum': '',
        'isWritten': false,
        'duplicateKey': -1
    }]);
    const [fileNameObj, setFileNameObj] = useState([{
        receipt1: {
            name: '',
            invalid: false,
            large: false,
            empty: '',
            statusFile: '',
            statusMsg: '',
            showName: false,
            file: null
        }
    }])
    const { translations, settings } = useContext(JsonContext);
    var Require_Captcha = settings.Require_Captcha,
        _interval,
        getBtnObj;

    if (webStorageService.getSessionData('btnObj') == undefined) {
        getBtnObj = [{
            'sended': false,
            'validateCaptchaHome': (Require_Captcha == "true") ? false : true,
            'anyError': false,
            'isEmpty': true,
            'validResponse': false
        }];
    } else {
        getBtnObj = JSON.parse(webStorageService.getSessionData("btnObj"));
    }
    const [btnObj, setbtnObj] = useState(getBtnObj)
    const [modalShown, setModalShown] = useState(false);
    let pageState = webStorageService.getSessionData('createPageState');

    // pageState = 'Batchcodeentry';
    if (pageState == '' || pageState == undefined || pageState == null) {
        window.location = '/' + locale + '/Home';
    } else if (pageState != 'Batchcodeentry') {
        return <Redirect to={'/' + locale + '/' + pageState} />
    }
    let smallViewPort;

    var toggleModal = function (event) {
        event.preventDefault();
        setModalShown(!modalShown)
        if (!modalShown) {
            $('html,body').removeClass('activeOverlay');
        }
        setTimeout(function () {
            positionModal();
        }, 5);
    };
    var positionModal = function () {
        if (modalShown) {
            if ($('.ng-modal-dialog').height() > ($(window).height() - 50)) {
                smallViewPort = false;
            }
            else {
                smallViewPort = true;
            }
        }
    }
    if (pageState == 'Batchcodeentry') {
        var upcErrorLabels = {
            'length': translations.CoreKelloggs_ErrorIncompleteCode,
            'pattern': translations.CoreKelloggs_ErrorInvalidCode,
            'redeemed': translations.CoreKelloggs_ErrorInvalidCode,
            'empty': translations.CoreKelloggs_ErrorCodeRequired,
            'duplicate': translations.CoreKelloggs_ErrorDuplicateCode,
            'invalid': translations.CoreKelloggs_ErrorInvalidCode
        },
            hidePromoCodeField,
            upcCount = settings.CodesCount,
            canImage = (locale == 'ru_RU') ? locale : 'en_GB';

        if (locale == 'lv_LV' || locale == 'bg_bg') {
            hidePromoCodeField = true;
        }
        
        $('.topMenuAdimo').keyup(function (e) {
            if (e.keyCode == 13) {
                $('.topMenuAdimo').click();
                e.preventDefault();
            }
        });
         
        const _interval1 = setInterval(function () {
            setAdimo();
        }, 300);
        var setAdimo = function () {
            if ($('.bg-home-img-cont').length) {
                if ((locale == 'en_GB')   && document.querySelectorAll('.buy-now').length == 0) {
                    var buyNowBtn = true;
                    // setTimeout(function () {
                    // $(function () {
                    // Adimo call
                    window.Adimo.main.init('https://basketads.adimo.co', false);
                    var strTxt = "<span class='adimoWidget' data-type='lightbox' data-static-touchpoint-identifier='42c13aab-cdea-463d-ad17-a8d44179035a'><button class='topMenuAdimo buy-now not-button' title='" + translations.CoreKelloggs_BuyNowAdimoLinkTitle + "'>Buy Now</button></span>";
                    var strTxt1 = "<span class='mThree nav-button'><a title='" + translations.CoreKelloggs_BuyNowAdimoLinkTitle + "' class='adimoWidget topMenuAdimo' data-type='lightbox' href='#'  data-static-touchpoint-identifier='42c13aab-cdea-463d-ad17-a8d44179035a'>'<span class='topMenuAdimo buy-now'>Buy Now</span>'</a></span>";
                    $(".bg-home-img-cont").after(strTxt);
                    $('.buy-now-txt').append(strTxt1);
                    //To add product detail page	
                    if ($(".pack-image span > span").data('src')) {
                        var product_image_path = "";
                        product_image_path = $(".pack-image span > span").data('src');
                        var product_id = product_image_path.split('/')[6];
                        var insertPosition = $(".kstl-product-description");
                        function returnTouchpointID(product_id) {
                            switch (product_id) {
                                case 'product_6786220':
                                    //Product - Pringles BBQ 200g
                                    var touchPointId = '344696b0-fd4d-4a19-a7c2-0a2652d75ced';
                                    break;
                                case 'product_6604916':
                                    //Product - Pringles BBQ 40g
                                    var touchPointId = '1027c729-b269-42e9-93b4-af2b9bf823d7';
                                    break;
                                case 'product_6574745':
                                    //Product - Pringles Cheese & Onion 200g
                                    var touchPointId = '4be34b8b-2d7e-41b3-9c10-73fbdb218130';
                                    break;
                                case 'product_6598149':
                                    //Product - Pringles Original 200g
                                    var touchPointId = 'd094270e-ae39-48ba-869f-a58c029a47ed';
                                    break;
                                case 'product_6589741':
                                    //Product - Pringles Original 40g
                                    var touchPointId = '5e73ef3c-ff83-4288-bb9d-fb5774fec452';
                                    break;
                                case 'product_6598149':
                                    //Product - Pringles Original 70g
                                    var touchPointId = '114919bf-91e9-4784-9c83-185cf8fdc97f';
                                    break;
                                case 'product_6581769':
                                    //Product - Pringles Paprika 200g
                                    var touchPointId = '48baf4f8-775f-484d-b25e-c0a811de438d';
                                    break;
                                case 'product_6589742':
                                    //Product - Pringles Paprika 40g
                                    var touchPointId = '1bbf72dc-c1af-4319-9025-991b733ea5a0';
                                    break;
                                case 'product_3310719':
                                    //Product - Pringles Prawn Cocktail 200g
                                    var touchPointId = '0af913d6-1b55-4f85-9513-88adfcb31d38';
                                    break;
                                case 'product_7387674':
                                    //Product - Pringles Rice Katsu Curry 160
                                    var touchPointId = '2b4296b2-1eea-461b-ba8c-75187abc15bd';
                                    break;
                                case 'product_3311028':
                                    //Product - Pringles Salt & Vinegar 200g
                                    var touchPointId = 'e3e6db4f-220c-4e43-bd11-d69522ff0476';
                                    break;
                                case 'product_6574775':
                                    //Product - Pringles Salt & Vinegar 40g
                                    var touchPointId = '661a3c38-c47d-42df-8993-c6b2cf90a400';
                                    break;
                                case 'product_11093244':
                                    //Product - Pringles Sizzl'N Cheese & Chilli 180g
                                    var touchPointId = '72bc4e39-6753-4381-8df6-3d522664d2b7';
                                    break;
                                case 'product_11092207':
                                    //Product - Pringles Sizzl'N Kickinâ€™ Sour Cream 180g
                                    var touchPointId = '4ec16765-399a-4b8b-86b2-cbc734a4ee0d';
                                    break;
                                case 'product_11093193':
                                    //Product - Pringles Sizzl'N Spicy BBQ 180g
                                    var touchPointId = '6ec2bfc5-7344-4732-b5ff-830b7c31bfa1';
                                    break;
                                case 'product_6609590':
                                    //Product - Pringles Smokey Bacon 200g
                                    var touchPointId = '99be6444-9379-459e-93d7-feab9b20187f';
                                    break;
                                case 'product_6598150':
                                    //Product - Pringles Sour Cream & Onion 200g
                                    var touchPointId = '3a6d9290-66e9-418a-9a09-3fa43c34d46d';
                                    break;
                                case 'product_3311040':
                                    //Product - Pringles Sour Cream & Onion 40g
                                    var touchPointId = 'f6d13daf-00dc-43b0-a22f-6de85a1ae8a3';
                                    break;
                                case 'product_6598150':
                                    //Product - Pringles Sour Cream & Onion 70g
                                    var touchPointId = '07c092e9-23bc-4ddf-a0be-547448c300d2';
                                    break;
                                case 'product_6605707':
                                    //Product - Pringles Steak 200g
                                    var touchPointId = 'e304aea1-d16c-44ba-8bf2-6bf2b3fdc8b2';
                                    break;
                                case 'product_6926477':
                                    //Product - Rice BBQ Teriyaki 160g
                                    var touchPointId = 'f04c808b-2b1b-446c-b2f4-aef422805421';
                                    break;
                                case 'product_6926473':
                                    //Product - Rice Malaysian Red Curry 160g
                                    var touchPointId = '6d8d139d-9793-4f15-bb70-9c9fdf2de076';
                                    break;
                                case 'product_6926471':
                                    //Product - Rice Peking Duck with Hoisin Sauce 160g
                                    var touchPointId = 'ba7eb203-3739-41fd-93a1-c704e4315834';
                                    break;
                                case 'product_6926475':
                                    //Product - Rice Tandoori Chicken Masala 160g
                                    var touchPointId = '0a299d1e-867f-4bdb-8668-0f7fe1f0ef26';
                                    break;
                                case 'Product_3978711':
                                    //Product - Tortilla Cheesy Nacho Cheese 160g
                                    var touchPointId = 'e2b0b9de-b750-4084-b19a-d1c4c27a2363';
                                    break;
                                case 'product_6231074':
                                    //Product - Tortilla Sour Cream 160g
                                    var touchPointId = 'eda72921-1398-41ff-8e55-de8f492955ce';
                                    break;
                            }
                            if (touchPointId) {
                                var strTxtDetailPage = "<div className='adimoWidget detail' data-type='lightbox' data-static-touchpoint-identifier='" + touchPointId + "'><button className='adimoBuyNow'>Buy Now</button></div>";
                            } else {
                                var strTxtDetailPage = "";
                            }
                            return strTxtDetailPage;
                        }
                        insertPosition.after(returnTouchpointID(product_id));
                    }
                    // });
                    // }, 1000);

                }
                clearInterval(_interval1);
            }
        }
        var imageValid = false,
            numValid = false,
            PromotionIdNotFound = false,
            codes = [],
            updateBtnOj = getBtnObj,
            receiptAdded,
            // $anchorScroll,
            // modalShown = false,
            coordinatesToReturn = {},
            lockCrossword = false,
            showlanguageSelector = true,
            fileName = [];
        var setCaptcha = function () {
            if ($('#upcCodeForm').length && $('.g-recaptcha-response').length < 1 && typeof window.grecaptcha.render !== 'undefined') {
                if (settings.Require_Captcha == "true") {
                    window.grecaptcha.render(
                        'g-recaptcha', {
                        sitekey: '6LeRF1scAAAAAB-yBjJMrzLYSBqNAGjkf2PokFq1',
                        callback: onSuccess,
                        'expired-callback': expCallback,
                        'hl': locale.split('_')[0]
                    }
                    );
                }
                clearInterval(_interval);
            }
        };
        _interval = setInterval(function () {
            setCaptcha();
        }, 300);

        function expCallback() {
            window.grecaptcha.reset();
            updateBtnOj = JSON.parse(webStorageService.getSessionData('btnObj'))
            updateBtnOj[0].validateCaptchaHome = false;
            webStorageService.setSessionData('btnObj', updateBtnOj);

            setbtnObj(updateBtnOj);
        }
        function onSuccess(token) {
            updateBtnOj = JSON.parse(webStorageService.getSessionData('btnObj'))
            if (token) {
                API.verifyCaptchaEnterCode({ token: token, redemptionToken: webStorageService.getSessionData('servToken') }).then(function (res) {
                    let updateValidateCaptchaHome = (res.status == 'FAILED') ? false : true;
                    updateBtnOj[0].validateCaptchaHome = updateValidateCaptchaHome;
                    webStorageService.setSessionData('btnObj', updateBtnOj);
                    setbtnObj(updateBtnOj);
                    webStorageService.setSessionData('servToken', res.token);
                }).catch(function (err) {
                    HandleError(err.response)
                })
            }
        }

        if (webStorageService.getSessionData('codes') == undefined) {
            for (var i = 1; i <= upcCount; i++) {
                codes.push({
                    'objId': i,
                    'id': 'Code' + i,
                    'errors': '',
                    'labels': 'BatchcodeSection1Label' + i,
                    'value': '',
                    'uploadId': 'uploadImg' + i,
                    'receiptNum': 'receipt' + i,
                    'isWritten': false,
                    'duplicateKey': -1
                });
            }
        } else {
            codes = JSON.parse(webStorageService.getSessionData("codes"));
        }

        var uploadReceipt = false;
        if (locale.toLowerCase() == 'it_it') {
            document.querySelector('body').className = 'activeOverlay Batchcodeentry';
            uploadReceipt = true;
            if (webStorageService.getSessionData('receiptObj') == undefined) {
                fileName = [{
                    receipt1: {
                        name: '',
                        invalid: false,
                        large: false,
                        empty: '',
                        statusFile: '',
                        statusMsg: '',
                        showName: false,
                        file: null
                    }
                }];
            } else {
                fileName = JSON.parse(webStorageService.getSessionData("receiptObj"));
            }
        } else {
            uploadReceipt = false;
        }

        var checkForDuplicates = function (e, index) {
            var value = e.target.value.toLowerCase();
            for (var i = 0; i < codes.length; i++) {
                if (value == codes[i].value.toLowerCase() && i != index && (!codes[i].errors || codes[i].errors == upcErrorLabels.duplicate)) {
                    codes[i].errors = upcErrorLabels.duplicate;
                    codes[index].errors = upcErrorLabels.duplicate;
                    //codes[i].duplicateKey = index;
                }
            }
            // setCodesArr(codes);
        }
        var verifyDuplicates = function (index) {
            var duplicateCount;
            for (var i = 0; i < codes.length; i++) {
                duplicateCount = 0;
                if (i != index && codes[i].errors == upcErrorLabels.duplicate) {
                    for (var j = 0; j < codes.length; j++) {
                        if (codes[i].value.toLowerCase() == codes[j].value.toLowerCase() && i != j) {
                            duplicateCount++;
                        }
                    }
                    if (!duplicateCount && codes[i].errors == upcErrorLabels.duplicate) {
                        codes[i].errors = '';
                    }
                }
            }

        }
        var updateErrorKey = function () {
            var flag = false;
            for (var i = 0; i < codes.length; i++) {
                if (codes[i].errors) {
                    flag = true;
                }
            }
            updateBtnOj[0].anyError = (flag ? true : false);
        }
        var onBlur = function (index, e) {
            // if(!item.$viewValue){
            //     _setInvalidFieldState(item.$name, 'required');
            // }
            if (e.target.value.length < e.target.maxLength && e.target.value.length) {
                codes[index].errors = upcErrorLabels.length;
            } else if (!e.target.value.length) {
                codes[index].errors = upcErrorLabels.empty;
                updateBtnOj[0].isEmpty = true;
            } else {
                updateBtnOj[0].isEmpty = false;
            }
            updateErrorKey();
            codes[index].isWritten = true;
            webStorageService.setSessionData("codes", codes);
            setCodesArrObj(codes);
            webStorageService.setSessionData('btnObj', updateBtnOj);
            setbtnObj(updateBtnOj);
            // alignVertically();
        };
        var userInput = function (e, index) {
            codes[index].value = e.target.value;
            if (e.target.value == 0 && (codes[index].isWritten || codes[index].errors)) {
                codes[index].errors = upcErrorLabels.empty;
            }
            else if (codes[index].isWritten || codes[index].errors) {
                let transformedInput = e.target.value.replace(/[^a-zA-Z0-9]/g, '');  
                e.target.value = transformedInput
                if (e.target.value.length < e.target.maxLength) {
                    codes[index].errors = upcErrorLabels.length;
                    updateErrorKey();
                }
                verifyDuplicates(index);
                updateBtnOj[0].isEmpty = false;
            }
            if (e.target.value.length == e.target.maxLength) {
                e.preventDefault();
            } else if (e.target.value.length > e.target.maxLength) {
                // Maximum exceeded
                e.target.value = e.target.value.substring(0, e.target.maxLength);
            }
            if (e.target.value.length == e.target.maxLength) {
                codes[index].errors = '';
                checkForDuplicates(e, index);
                updateErrorKey();
            }
            webStorageService.setSessionData("codes", codes);
            setCodesArrObj(codes);
            webStorageService.setSessionData('btnObj', updateBtnOj);
            setbtnObj(updateBtnOj);
            // alignVertically();
        }
        var _setStatuses = function (response) {
            var valid = true,
                statusesArr = response.result,
                statusCheck = true,
                imageResponse = uploadReceipt ? response.imageResult : '',
                receiptNum, scrollElement, filename;
            codes = webStorageService.getSessionData("codes") ? JSON.parse(webStorageService.getSessionData("codes")) : '';
            filename = webStorageService.getSessionData("receiptObj") ? JSON.parse(webStorageService.getSessionData("receiptObj")) : '';
            if (uploadReceipt && !!imageResponse) {
                for (i = 0; i < imageResponse.length; i++) {
                    if (imageResponse[i].Status.toLowerCase() == 'failed') {
                        valid = false;
                        if (Require_Captcha === "true") {
                            window.grecaptcha.reset();
                            updateBtnOj[0].validateCaptchaHome = false;
                            // setbtnObj({...btnObj,validateCaptchaHome:false});
                        }
                        $('.upload-img').eq(i).val(null);
                        receiptNum = 'receipt' + (i + 1);
                        filename[0][receiptNum].statusFile = true;
                        filename[0][receiptNum].showName = false;
                        receiptAdded = false;
                        filename[0][receiptNum].statusMsg = imageResponse[i].Message;
                        filename[0][receiptNum].file = null;
                        filename[0][receiptNum].name = '';
                        webStorageService.setSessionData('receiptObj', filename);
                        setFileNameObj(filename);
                        //someCtrlFn(receiptNum, null, false);
                        if (statusCheck) {
                            scrollElement = 'uploadReceipt' + (i + 1);
                            statusCheck = false;
                        }
                    }
                }
            }
            for (i = 0; i < statusesArr.length; i++) {
                if (statusesArr[i].status.toLowerCase() != 'valid') {
                    updateBtnOj[0].anyError = true;
                    updateBtnOj[0].validResponse = false;

                    if (Require_Captcha === "true") {
                        window.grecaptcha.reset();
                        updateBtnOj[0].validateCaptchaHome = false;
                        // validateCaptchaHome = false;
                        // setbtnObj(updateBtnOj);

                    }
                    webStorageService.setSessionData('btnObj', updateBtnOj);
                    setbtnObj(updateBtnOj);
                    codes[i].errors = upcErrorLabels[statusesArr[i].status.toLowerCase()];
                    valid = false;
                    if (statusCheck) {
                        scrollElement = 'Code' + (i + 1);
                        statusCheck = false;
                        // $anchorScroll.yOffset = 100;
                    }
                }
            }
            if (!statusCheck) {
                // $anchorScroll(scrollElement);
                // $('#' + scrollElement).focus();
            }
            webStorageService.setSessionData("codes", codes);
            setCodesArrObj(codes);

            //$location.hash(scrollElement);
            // call $anchorScroll()
            // alignVertically();
            return valid;
        }
        var sendForm = function (e) {
            e.preventDefault();
            receiptAdded = (uploadReceipt) ? checkEntries() : true;
            if (!receiptAdded) {
                setTimeout(function () {
                    if ($('.blank-msg').eq(0).length > 0) {
                        var elem = $('.blank-msg').eq(0).closest('.upload-receipt').attr('id');
                        // $anchorScroll(elem);
                    }
                }, 300);
            } else {
                updateBtnOj[0].sended = true;
                updateBtnOj[0].validResponse = true;
                updateBtnOj[0].validateCaptchaHome = false;
                webStorageService.setSessionData('btnObj', updateBtnOj);
                setbtnObj(updateBtnOj);

                var form = new FormData(),
                    data, codesArr = [],
                    codesInput = webStorageService.getSessionData("codes") ? JSON.parse(webStorageService.getSessionData("codes")) : '',
                    filenameVal = webStorageService.getSessionData("receiptObj") ? JSON.parse(webStorageService.getSessionData("receiptObj")) : '';

                for (var i = 0; i < codesInput.length; i++) {
                    codesArr[i] = $.trim(codesInput[i].value).toUpperCase();
                }
                if (locale.toLowerCase() == 'it_it') {
                    for (var i = 0; i < 1; i++) {

                        // form.append('receipt[]', filenameVal[0]['receipt' + (i + 1)].file);
                        form.append('PartNumber', filenameVal[0]['receipt' + (i + 1)].file);

                    }
                    for (var i = 0; i < codesArr.length; i++) {

                        form.append('Codes[]', codesArr[i]);
                    }
                    data = form;
                } else {
                    data = codesArr;
                }
                API.checkUpcCodes(data, webStorageService.getSessionData('servToken')).then(function (response) {
                    updateBtnOj[0].sended = false;
                    webStorageService.setSessionData('btnObj', updateBtnOj);
                    setbtnObj(updateBtnOj);
                    if (!!response.result && response.result.length > 0) {
                        if (_setStatuses(response) && response.token) {
                            webStorageService.setSessionData('servToken', response.token);
                            if (locale.toLowerCase() != 'lv_lv' && locale.toLowerCase() != 'bg_bg') {
                                dataLayers().packCodeData();
                            }
                            if (locale.toLowerCase() === 'ru_ru') {
                                var shipObj = webStorageService.getSessionData('shippingInfoObj');
                                var shippingInfoObj = {
                                    'redemption-token': response.token,
                                    'shippingDetails': {
                                        name: shipObj.shippingDetails.name,
                                        lastName: shipObj.shippingDetails.lastName,//formObj.LastName,
                                        address1: shipObj.shippingDetails.address1,//formObj.Address1,
                                        address2: shipObj.shippingDetails.address2,//formObj.Address2,
                                        city: shipObj.shippingDetails.city,//formObj.City,
                                        postCode: shipObj.shippingDetails.postCode,//formObj.PostalCode,
                                        state: shipObj.shippingDetails.state,
                                        Email: shipObj.shippingDetails.Email,
                                        terms: shipObj.shippingDetails.terms,
                                        subscription: shipObj.shippingDetails.subscription,
                                        phone: shipObj.shippingDetails.phone,
                                        country: shipObj.shippingDetails.country// resourceService.getCountryCode().toLowerCase() // add russia
                                    }
                                };
                            }
                            else {
                                var grm = webStorageService.getSessionData('grmUser');
                                var shippingInfoObj = {
                                    'redemption-token': webStorageService.getSessionData('servToken'),
                                    'shippingDetails': grm
                                };
                            }

                            /*sended = true;*/

                            API.orderAward(shippingInfoObj).then(function (response) {
                                // sended = false;
                                /*disableRegister = false;*/
                                if (response.token) {
                                    // var token = response.token;
                                    webStorageService.setSessionData('servToken', response.token);
                                    webStorageService.setLocalData('servToken', response.token);
                                } else {
                                    webStorageService.removeSessionData('servToken');
                                }
                                if (response.redirect) {
                                    updateBtnOj[0].validResponse = false;
                                    webStorageService.setSessionData('btnObj', updateBtnOj);
                                    setbtnObj(updateBtnOj);
                                    var winnerObj = {};
                                    if (response.redirect.toLowerCase().indexOf('thankyou') > -1) {
                                        if (response.Winner !== undefined && response.Winner !== null) {
                                            winnerObj = {
                                                winner: response.Winner,
                                                winnerCopy: response.winnerCopy,
                                                isExtendedPhase: response.ExtendedPhase,
                                                refCode: response.RefCode
                                            }
                                            if (response.Winner > 0) {
                                                dataLayers().orderData(response.Winner);
                                            }
                                        }
                                    }
                                    if (response.redirect.indexOf('limit') > -1) {
                                        webStorageService.setSessionData('limitType', response.message);
                                    }
                                    handleRoute(response.redirect, winnerObj);
                                } else {
                                    // $location.path(locale + '/Error');
                                }
                            }).catch(function (error) {
                                HandleError(error.response);
                            });
                        }

                    }
                }).catch(function (error) {
                    HandleError(error.response);
                });
            }
        };
        // var onClick = function (clicks) {
        //     clicks = clicks;
        //     this.options.registerClicks(clicks);
        // }
        var checkEntries = function () {
            receiptAdded = true;
            let filenameVal = webStorageService.getSessionData("receiptObj") ? JSON.parse(webStorageService.getSessionData("receiptObj")) : [{
                receipt1: {
                    name: '',
                    invalid: false,
                    large: false,
                    empty: '',
                    statusFile: '',
                    statusMsg: '',
                    showName: false,
                    file: null
                }
            }];
            for (var key in filenameVal[0]) {
                if (filenameVal[0][key].name.length < 1) {
                    filenameVal[0][key].empty = true;
                    filenameVal[0][key].invalid = false;
                    filenameVal[0][key].large = false;
                    filenameVal[0][key].statusFile = false;
                } else {
                    filenameVal[0][key].empty = false;
                }
                if (filenameVal[0][key].file == null) {
                    receiptAdded = false;
                }
            }
            webStorageService.setSessionData('receiptObj', filenameVal);
            setFileNameObj(filenameVal);
            return receiptAdded;
        }
        // var someCtrlFn = function (arg1, arg2, field) {
        //     var validEntry = true;
        //     filename[arg1].file = arg2;
        //     imageValid = false;
        //     if (field) {
        //         filename[arg1].statusFile = false;
        //     }
        //     receiptAdded = true;
        // };

        var onTextChange = function (e, index) {
            var $el = $(e.target);
            var maxLength = $el.attr('maxLength');
            maxLength = maxLength ? maxLength : 19;
        };
        var onUploadClick = function ($event) {
            $event.preventDefault();
            var triggerEl = $('#' + $($event.target).data('clickfor'));
            triggerEl.trigger('click');
        };
        var validType = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png']
            , validSize = 5;
        var onChangeReceipt = function (event) {
            const reader = new FileReader();
            let file = event.target.files[0],
                elementName = event.target.name;
            fileName[0][elementName].name = '';
            fileName[0][elementName].invalid = false;
            fileName[0][elementName].size = false;
            fileName[0][elementName].showName = false;
            fileName[0][elementName].statusFile = false;
            fileName[0][elementName].large = false;
            fileName[0][elementName].empty = false;

            if (file == undefined) {
                file = null;
            }
            else if (validType.indexOf(file.type) < 0) {
                event.target.value = null;
                file = null;
                fileName[0][elementName].invalid = true;
                fileName[0][elementName].large = false;
            }
            else if ((file.size) / (1024 * 1024) > validSize) {
                event.target.value = null;
                file = null;
                fileName[0][elementName].invalid = false;
                fileName[0][elementName].large = true;
            }
            else {
                fileName[0][elementName].name = file.name;
                fileName[0][elementName].showName = true;
                dataLayers().imageUpload()
            }
            reader.addEventListener("load", function () {
                // convert image file to base64 string
                fileName[0][elementName].file = reader.result;
                webStorageService.setSessionData('receiptObj', fileName);
                setFileNameObj(fileName);
            }, false);

            if(file){
                reader.readAsDataURL(file);
            }else{
                setFileNameObj(fileName);
            }


        }
        var isOutOfHours = webStorageService.getSessionData('pageState') ?
            webStorageService.getSessionData('pageState') === 'Outofhours' : false;
        //isOutOfHours=true;
        // if (isOutOfHours) dataLayers().OutOfHours();
        var goToPringlesSite = function () {
            // dataLayers().goToPringles('Out of Hours Page');
        };

        document.querySelector('body').setAttribute('pagename', 'Batchcodeentry');

    }


    return (
        <>
            <MetaTags
                title={translations.BatchcodePageTitle + ' | ' + translations.CampaignName}
                description={translations.MetaContent}
                keywords=''
            />
            <section className="batchcode-page">
                <div className="twocols" >
                    <div>
                        <div className="bg-home js-col-with-form" >
                            <h1>{translations.BatchcodeSectionTitle}</h1>
                            <p>{translations.BatchcodeSectionDescription}</p>
                            <p dangerouslySetInnerHTML={{ __html: translations.BatchcodeSectionDescription1 }}></p>
                            <p>{translations.BatchcodeSectionDescription2}</p>

                            <p className="tnclink">
                                <span><sup>▴</sup></span>
                               <a target="_blank" href={'/' + locale + '/TERMS'}
                                    title={translations.CoreKelloggs_TncsApplyLinkTitle}
                                    dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_TncApply }}></a>
                            </p> 

                            <div>
                                <form id="upcCodeForm" name="upcForm" method="post" className="form-box" noValidate="novalidate">
                                    <div className="form-control" >
                                        <p>{translations.BatchcodeSectionInstruction}</p>
                                        <p>{translations.BatchcodeSectionInstruction1}</p>
                                        {
                                            codes.map(function (code, index){
                                                return (
                                                    <div key={index}>
                                                        <label htmlFor={code.id}>{translations.BatchcodeSectionLabel}</label>

                                                        <div  role="alert" aria-atomic="true" className={codesArrObj[index].errors ? 'error error-message' : 'error-message'}>
                                                            <p>
                                                                <span>{codesArrObj[index].errors}</span>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <input type="text" id={code.id} name={code.id} required={true} autoComplete="off" minLength='19' maxLength='19'
                                                                pattern="^([A-Za-z0-9]{19})$" placeholder="X-XXXXXXXXXX-XXXX-XXXX"
                                                                onChange={(e) => userInput(e, index)}
                                                                onBlur={(e) => onBlur(index, e)}
                                                                className={codesArrObj[index].errors && 'ng-error'}
                                                                onKeyUp={(e) => onTextChange(e, index)}
                                                                title={translations.CoreKelloggs_EnterCodeInputFieldTitle} />

                                                        </div>
                                                        {
                                                            isShowReceiptField && <div className="upload-receipt" id={'uploadReceipt' + (index + 1)}>
                                                                <p>
                                                                    <span>{translations.CoreKelloggs_UploadImageHeader}</span>
                                                                </p>
                                                                {fileNameObj[0][code.receiptNum].invalid && <span className="error">{translations.CoreKelloggs_UploadImageTypeError}</span>}
                                                                {fileNameObj[0][code.receiptNum].large && <span className="error">{translations.CoreKelloggs_UploadImageSizeError}</span>}
                                                                {fileNameObj[0][code.receiptNum].empty && <span className="error blank-msg">{translations.CoreKelloggs_UploadImageMandatoryError}</span>}
                                                                {fileNameObj[0][code.receiptNum].statusFile && <span className="error" data-attr={fileNameObj[0][code.receiptNum]}>{fileNameObj[0][code.receiptNum].statusMsg}</span>}
                                                                {fileNameObj[0][code.receiptNum].showName && <span className="file-name" ><span className="icon"></span>{fileNameObj[0][code.receiptNum].name}</span>
                                                                }
                                                                <div className="receipt-wrapper">
                                                                    <a href="#" data-clickfor={code.receiptNum} className="button small not-button" onClick={(e) => onUploadClick(e)} title={translations.CoreKelloggs_ReceiptUploadCtaTitle}>{translations.CoreKelloggs_UploadImageCta}
                                                                    </a>
                                                                    <input required={true} name="receipt1" type="file" accept="image/*" id="receipt1" className="upload-img ng-isolate-scope" data-asd="receipt1" data-code="code" data-file-name="filename" data-options="options" onChange={(e) => onChangeReceipt(e)} data-check-entries="checkEntries" />

                                                                    <div className="info-wrapper"><a href="#" className="btn-info" onClick={(event) => toggleModal(event)} title={translations.CoreKelloggs_ReceiptUploadIconTitle}><span>i</span></a></div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="form-control captcha-box">
                                        <div id="g-recaptcha" className="g-recaptcha" sitekey="6LeRF1scAAAAAB-yBjJMrzLYSBqNAGjkf2PokFq1"></div>
                                    </div>
                                    <button id="homeBtn" type="submit" onClick={(e) => sendForm(e)}
                                        disabled={btnObj[0].sended || btnObj[0].anyError || !btnObj[0].validateCaptchaHome || btnObj[0].isEmpty}
                                        className={(btnObj[0].validResponse) ? 'inactive processing' : (btnObj[0].sended || btnObj[0].anyError || !btnObj[0].validateCaptchaHome || btnObj[0].isEmpty) ? 'inactive' : ''}
                                        title={translations.EnterNowCtaTitle}
                                        aria-disabled={btnObj[0].sended || btnObj[0].anyError || !btnObj[0].validateCaptchaHome || btnObj[0].isEmpty}
                                    >
                                        <span>{translations.CoreKelloggs_BatchcodeSectionCta}</span>
                                        <i className="loader-wrapper" ><span className="loader"></span></i>
                                    </button>
                                    {
                                        (locale == 'en_GB' ) && <p className="buy-now-txt">Need another Pringles can?<br />You are just a click away. </p>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="bg-home-img-cont js-col-without-form">
                            <h2>{translations.CoreKelloggs_CodehelpSection1Title}</h2>
                            <picture picturefill="true">
                                {/* <!--[if IE 9]><video style="display: none;"><![endif]--> */}
                                <source srcSet={'/Content/img/can-visuals/celebrate2022-can-' + canImage + '.png'}
                                    media="only screen and (min-width: 768px)" />
                                <source srcSet={'/Content/img/can-visuals/celebrate2022-can-' + canImage + '.png'}
                                    media="only screen and (max-width: 767px)" />
                                {/* <!--[if IE 9]></video><![endif]--> */}
                                <img src={'/Content/img/can-visuals/celebrate2022-can-' + canImage + '.png'}
                                    alt={translations.CanImageAltText} />
                            </picture>
                            <p className="below-can">{translations.CodehelpSection1Instruction}</p>
                        </div>
                    </div>
                </div>
                {modalShown && <div className='ng-modal-wrapper' >
                    {modalShown && <div className='ng-modal'>
                        <div className='ng-modal-overlay' onClick={(event) => toggleModal(event)}></div>
                        <div className='ng-modal-dialog'
                            data-smallv={String(smallViewPort)}>
                            <div className='ng-modal-close' onClick={(event) => toggleModal(event)}></div>
                            <div className='ng-modal-dialog-content'>
                                <div className="heading-container">
                                    {translations.CoreKelloggs_Uploadimageinstructionsheading && <h2>{translations.UploadImageInstructions}</h2>}
                                </div>
                                <div className="instructions-list">
                                    <p>{translations.UploadImageStep1}</p>
                                    <p>{translations.UploadImageStep2}</p>
                                    <p>{translations.UploadImageStep3}</p>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>}
            </section>
        </>
    )
}

export default Batchcode;


