import React, { useState, useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { locale, MetaTags, $, pageScroll } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
import * as webStorageService from '../Utility/WebStorageFactory';
import * as API from '../Utility/ApiCalls';
import ErrorProvider from '../Utility/ErrorProvider';

const Shipping = () => {
    const { translations, settings } = useContext(JsonContext);
    var shippingDefault, Require_RU_Captcha = settings.Require_Captcha;
    if (webStorageService.getSessionData('shippingBtnObj') == undefined) {
        shippingDefault = [{
            UserDetails: {
                FirstName: {
                    isValid: false,
                    isEmpty: true,
                    error: false,
                    value: '',
                    isWritten: false,
                },
                LastName: {
                    isValid: false,
                    isEmpty: true,
                    error: false,
                    value: '',
                    isWritten: false,
                },
                Address1: {
                    isValid: false,
                    isEmpty: true,
                    error: false,
                    value: '',
                    isWritten: false,
                },
                Address2: {
                    isValid: false,
                    isEmpty: true,
                    error: false,
                    value: '',
                    isWritten: false,
                },
                City: {
                    isValid: false,
                    isEmpty: true,
                    error: false,
                    value: '',
                    isWritten: false,
                },
                PostalCode: {
                    isValid: false,
                    isEmpty: true,
                    error: false,
                    value: '',
                    isWritten: false,
                },
                Email: {
                    isValid: false,
                    isEmpty: true,
                    error: false,
                    value: '',
                    isWritten: false,
                },
                Phone: {
                    isValid: false,
                    isEmpty: true,
                    error: false,
                    value: '',
                    isWritten: false,
                },
                TermsandConditions: {
                    isEmpty: true,
                    error: false
                },
                sended: false,
                processing: false,
                validateCaptcha: (locale == 'ru_RU' && Require_RU_Captcha == "true") ? false : true
            },
        }]
    } else {
        shippingDefault = JSON.parse(webStorageService.getSessionData("shippingBtnObj"));
    }

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [shipping, setShipping] = useState(shippingDefault);
    const [tncCheckbox, setTncCheckbox] = useState(false);
    const [privacyCheckbox, setPrivacyCheckbox] = useState(false);
    const [radioBtnCntry, setRadioBtnCntry] = useState(false);
    const history = useHistory();
    const {HandleError} = ErrorProvider();
    const handleRoute = (path) => {
        history.push({pathname:path});
        pageScroll();
    }
    document.querySelector('body').setAttribute('pagename', 'Shipping');

    var ua = navigator.userAgent.toLowerCase(),
        isAndroid = ua.indexOf("android") > -1,
        // processing = false,
        // Require_RU_Captcha = translations.Require_Captcha,
        // validateCaptcha = (locale == 'ru_RU' && Require_RU_Captcha == "true") ? false : true,
        RIChecked = false,
        tcChecked = false,
        countrySel = false,
        countryValue = '',
        UserDetails = '',
        _interval,
        sended = false,
        isIRL = (locale === 'ie' ? true : false);

    if ($('.hiding').length > 0) {
        $('.hiding').addClass('hide');
    }
    // $('html, body').animate({
    //     scrollTop: $('header').offset().top - 100
    // }, 2000); 
    var setCaptcha = function () {
        if ($('#sdForm').length && $('.g-recaptcha-response').length < 1 && typeof window.grecaptcha.render !== 'undefined') {
            if (locale == 'ru_RU' && Require_RU_Captcha == "true") {
                window.grecaptcha.render(
                    'g-recaptcha', {
                    sitekey: '6LeRF1scAAAAAB-yBjJMrzLYSBqNAGjkf2PokFq1',
                    callback: onSuccess,
                    'expired-callback': expCallback,
                    'hl': 'ru'
                }
                );
            }
            clearInterval(_interval);
        }
    };
    _interval = setInterval(function () {
        setCaptcha();
    }, 300);
    // countrySelected = function(event) {
    //     countrySel = true;
    //     console.log(event);
    // }
    function expCallback() {
        window.grecaptcha.reset();
        shippingDefault = JSON.parse(webStorageService.getSessionData('shippingBtnObj'))
        shippingDefault[0].UserDetails.validateCaptcha = false;
        webStorageService.setSessionData('shippingBtnObj', shippingDefault);
        setShipping(shippingDefault);
    }
    function onSuccess(token) {
        shippingDefault = webStorageService.getSessionData('shippingBtnObj') == undefined ? shippingDefault : JSON.parse(webStorageService.getSessionData('shippingBtnObj'));
        var redemToken = webStorageService.getSessionData('servToken') ? webStorageService.getSessionData('servToken') : '';
        if (token) {
            API.verifyCaptcha({ token: token, redemptionToken: redemToken }).then(function (res) {
                let updateValidateCaptcha = (res.status == 'FAILED') ? false : true;
                shippingDefault[0].UserDetails.validateCaptcha = updateValidateCaptcha;
                webStorageService.setSessionData('shippingBtnObj', shippingDefault);
                setShipping(shippingDefault);
                webStorageService.setSessionData('servToken', res.token);
            }).catch(function (err) {
                console.log(err)
            })
        }
    }
    var oncheck = function (event) {
        var keyCode = event.which || event.keyCode;
        if (keyCode == 13) {
            if (event.target.attributes.for.value == 'KSTL-Registration-TermsandConditions') {
                setTncCheckbox(!tncCheckbox);
            } else {
                setPrivacyCheckbox(!privacyCheckbox);
            }
        }
    }
    var limitInput = function (event) {
        if (isAndroid) {
            var AddressLen = event.target.maxLength;
            if (event.target.value.length > AddressLen) {
                event.target.value = event.target.value.substring(0, event.target.value - 1);
            }
        }
    }
    var userInput = function (event) {
        let attrVal = event.target.attributes.name.value,
            getPattern = attrVal == 'Address1' || attrVal == 'Address2' ? '' : event.target.attributes.pattern.value,
            pattern = RegExp(getPattern);
        shippingDefault[0].UserDetails[attrVal].isWritten = true;
        if (!event.target.value.length) {
            shippingDefault[0].UserDetails[attrVal].isEmpty = true;
            shippingDefault[0].UserDetails[attrVal].error = true;
        }else {
            shippingDefault[0].UserDetails[attrVal].isEmpty = false;
            if (!pattern.test(event.target.value)) {
                shippingDefault[0].UserDetails[attrVal].isValid = false
                shippingDefault[0].UserDetails[attrVal].error = true;
            } else {
                if(( attrVal == 'PostalCode'|| attrVal == 'Phone') && event.target.value.length < parseInt(event.target.attributes.minlength.value) ){
                    shippingDefault[0].UserDetails[attrVal].isValid = false
                    shippingDefault[0].UserDetails[attrVal].error = true;
                }else{
                    shippingDefault[0].UserDetails[attrVal].error = false;
                    shippingDefault[0].UserDetails[attrVal].isValid = true;
                    shippingDefault[0].UserDetails[attrVal].value = event.target.value;
                }
            }
        }

        webStorageService.setSessionData('shippingBtnObj', shippingDefault);
        setShipping(shippingDefault);

    }
    var CountryValue = 'Russia',
        //sets default checked attribute for Terms
        TermsValues = true,
        onlyNumbers = function (evt) {
            if ((evt.which < 48 && evt.which > 41) || evt.which > 57) {
                evt.preventDefault();
            }
        }
    //public metodhs
    // setOrder = setOrder;
    function setOrder(event) {
        event.preventDefault();
        if (!shipping[0].UserDetails.FirstName.isEmpty && !shipping[0].UserDetails.LastName.isEmpty && !shipping[0].UserDetails.Address1.isEmpty && !shipping[0].UserDetails.Address2.isEmpty && !shipping[0].UserDetails.City.isEmpty && !shipping[0].UserDetails.PostalCode.isEmpty && !shipping[0].UserDetails.processing && shipping[0].UserDetails.validateCaptcha) {
            shippingDefault[0].UserDetails.sended = true;
            shippingDefault[0].UserDetails.processing = true;
            webStorageService.setSessionData('')
            setShipping(shippingDefault);
            var shippingInfoRussiaObj = {
                'redemption-token': webStorageService.getSessionData('servToken'),
                'UserName': shipping[0].UserDetails.Email.value,
                'ua': ua,
                'shippingDetails': {
                    name: shipping[0].UserDetails.FirstName.value,
                    lastName: shipping[0].UserDetails.LastName.value,//formObj.LastName,
                    address1: shipping[0].UserDetails.Address1.value,//formObj.Address1,
                    address2: shipping[0].UserDetails.Address2.value,//formObj.Address2,
                    city: shipping[0].UserDetails.City.value,//formObj.City,
                    postCode: shipping[0].UserDetails.PostalCode.value,//formObj.PostalCode,
                    state: '',
                    Email: shipping[0].UserDetails.Email.value,
                    terms: tncCheckbox,
                    subscription: privacyCheckbox,
                    phone: shipping[0].UserDetails.Phone.value,
                    country: radioBtnCntry// resourceService.getCountryCode().toLowerCase() // add russia
                }
            };
                    API.russiaOrder(shippingInfoRussiaObj).then(function(response){
                        if(response.token){
                            webStorageService.setSessionData('servToken', response.token);
                            webStorageService.setLocalData('servToken', response.token);
                            // token = response.token;
                            var shippingInfoObj = {
                                'redemption-token': response.token,
                                'shippingDetails': {
                                    name: shipping[0].UserDetails.FirstName.value,
                                    lastName: shipping[0].UserDetails.LastName.value,//formObj.LastName,
                                    address1: shipping[0].UserDetails.Address1.value,//formObj.Address1,
                                    address2: shipping[0].UserDetails.Address2.value ,//formObj.Address2,
                                    city: shipping[0].UserDetails.City.value,//formObj.City,
                                    postCode: shipping[0].UserDetails.PostalCode.value,//formObj.PostalCode,
                                    state: '',
                                    Email:shipping[0].UserDetails.Email.value,
                                     terms:tncCheckbox, 
                                    subscription:privacyCheckbox,
                                    phone: shipping[0].UserDetails.Phone.value,
                                    country: 'ru'// resourceService.getCountryCode().toLowerCase() // add russia
                                }
                            };
                            webStorageService.setSessionData('shippingInfoObj',shippingInfoObj);
                            webStorageService.setSessionData('createPageState', 'Batchcodeentry');
                            handleRoute("/" + locale + "/BatchCodeEntry");                
                        }
                        else if(response.redirect){
                            if (response.redirect.indexOf('limit') > -1) {
                                webStorageService.setSessionData('limitType', response.message);
                                webStorageService.setSessionData('createPageState', 'Limit');
                                handleRoute(response.redirect);    
                            } 
                        }
                        else{
                            // $location.path(lang + '/Error');
                        }
                    }).catch(function(error){
                        HandleError(error.response);
                    });
        }
    }
    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_RegisterPageTitle}
                description={translations.CoreKelloggs_RegisterPageDesc}
                keywords={translations.CoreKelloggs_RegisterPageKeywords}
            />
            <section className="shipping-details-page">
                <h1>{translations.LoginPageHeading}</h1>
                <div className="hiding" role="alert">{translations.LoginPageHeading}</div>
                <p>{translations.LoginPageDescription}</p>
                <p className="tnclink">
                    <span><sup>▴</sup></span>
                    <a target="_blank" href={locale} title={translations.CoreKelloggs_TncsApplyLinkTitle} dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_TncApply }}></a>
                </p>
                <h2 className="register-heading">{translations.CoreKelloggs_KpsShippingPageHeading}</h2>
                <p dangerouslySetInnerHTML={{ __html: translations.CoreKelloggs_KpsMandatoryFieldText }}></p>
                <form className="form-box shipping" id="sdForm" name="shipping.UserDetails" method="post" noValidate>
                    {/* <!-- col-first --> */}
                    <div id="pre-populate" className="form-control">
                        <input type="checkbox" name="PrePopulate" id="PrePopulate" data-ng-click="shipping.prePopulate($event)" data-ng-model="shipping.PrePopulateValue" className="ng-pristine ng-untouched ng-valid ng-empty" />
                        {/* <!--<label for="PrePopulate" data-ng-bind-html="main.resources.ShippingPrepopulateLabel"></label>--> */}
                    </div>
                    {/* <!-- first name form-control --> */}
                    <div id="shipping-firstname" className="form-control form-control--left">
                        <label htmlFor="FirstName">{translations.CoreKelloggs_KpsFirstname}</label>
                        <input type="text" name="FirstName" id="FirstName" maxLength="50"
                            required={true}
                            onKeyUp={($event) => limitInput($event)}
                            onChange={($event) => userInput($event)}
                            pattern="^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,50}$"
                            className={shipping[0].UserDetails.FirstName.isValid ? 'form-valid-field' : ''} />
                        <div className="error-wrapper">
                            {
                                (shipping[0].UserDetails.FirstName.isEmpty && shipping[0].UserDetails.FirstName.error) &&
                                <div className="error-message error">
                                    <p>
                                        <span>{translations.CoreKelloggs_FieldRequiredError}</span>
                                    </p>
                                </div>
                            }
                            {
                                !shipping[0].UserDetails.FirstName.isValid && !shipping[0].UserDetails.FirstName.isEmpty && <div className="error-message error"
                                >
                                    <p>
                                        <span>{translations.CoreKelloggs_KpsFirstnameError}</span>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    {/* <!-- end: first name form-control -->
                    
                    <!-- last name form-control --> */}
                    <div id="shipping-lastname" className="form-control form-control--right">
                        <label htmlFor="LastName">{translations.CoreKelloggs_KpsSurname}</label>
                        <input type="text" name="LastName" id="LastName" maxLength="50"
                            onKeyUp={($event) => limitInput($event)}
                            onChange={($event) => userInput($event)}
                            required={true}
                            pattern="^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,50}$"
                            className={shipping[0].UserDetails.LastName.isValid ? 'form-valid-field' : ''} />
                        <div className="error-wrapper">
                            {
                                (shipping[0].UserDetails.LastName.isEmpty && shipping[0].UserDetails.LastName.error) &&
                                <div className="error-message error">
                                    <p>
                                        <span>{translations.CoreKelloggs_FieldRequiredError}</span>
                                    </p>
                                </div>
                            }
                            {
                                !shipping[0].UserDetails.LastName.isValid && !shipping[0].UserDetails.LastName.isEmpty && <div className="error-message error"
                                >
                                    <p>
                                        <span>{translations.CoreKelloggs_KpsSurnameError}</span>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    {/* <!-- end: last name form-control --> */}

                    {/* <!-- address1 form-control --> */}
                    <div id="shipping-addr1" className="form-control form-control--left">
                        <label htmlFor="Address1">{translations.CoreKelloggs_KpsAddress1}</label>
                        <input type="text" name="Address1" id="Address1" maxLength="100"
                            onKeyUp={($event) => limitInput($event)}
                            onChange={($event) => userInput($event)}
                            required={true}
                            className={shipping[0].UserDetails.Address1.isValid ? 'form-valid-field' : ''}
                        />
                        <div className="error-wrapper">
                            {
                                (shipping[0].UserDetails.Address1.isEmpty && shipping[0].UserDetails.Address1.error) &&
                                <div className="error-message error">
                                    <p>
                                        <span>{translations.CoreKelloggs_KpsAddress1Error}</span>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    {/* <!-- end: address1 form-control -->
                    
                    <!-- address2 form-control --> */}
                    <div id="shipping-addr2" className="form-control form-control--right">
                        <label htmlFor="Address2">{translations.CoreKelloggs_KpsAddress2}</label>
                        <input type="text" name="Address2" id="Address2" maxLength="100"
                            onKeyUp={($event) => limitInput($event)}
                            onChange={($event) => userInput($event)}
                            required={true}
                            className={shipping[0].UserDetails.Address2.isValid ? 'form-valid-field' : ''}
                        />
                        <div className="error-wrapper">
                            {
                                (shipping[0].UserDetails.Address2.isEmpty && shipping[0].UserDetails.Address2.error) &&
                                <div className="error-message error">
                                    <p>
                                        <span>{translations.CoreKelloggs_FieldRequiredError}</span>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    {/* <!-- end: address2 form-control -->
                    
                    <!-- town/city form-control --> */}
                    <div id="shipping-towncity" className="form-control form-control--left">
                        <label htmlFor="City">{translations.CoreKelloggs_KpsCity}</label>

                        <input type="text" name="City" id="City" maxLength="50"
                            onKeyUp={($event) => limitInput($event)}
                            pattern="^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,50}$"
                            onChange={($event) => userInput($event)}
                            required={true}
                            className={shipping[0].UserDetails.City.isValid ? 'form-valid-field' : ''} />
                        <div className="error-wrapper">
                            {
                                (shipping[0].UserDetails.City.isEmpty && shipping[0].UserDetails.City.error) &&
                                <div className="error-message error">
                                    <p>
                                        <span>{translations.CoreKelloggs_FieldRequiredError}</span>
                                    </p>
                                </div>
                            }
                            {
                                !shipping[0].UserDetails.City.isValid && !shipping[0].UserDetails.City.isEmpty && <div className="error-message error"
                                >
                                    <p>
                                        <span>{translations.CoreKelloggs_KpsCityError}</span>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    {/* <!-- end: town/city form-control -->
                    
                    <!-- postal code form-control --> */}
                    <div id="shipping-postalcode" className="form-control form-control--right">
                        <label htmlFor="PostalCode">{translations.CoreKelloggs_KpsPostcode}</label>
                        <input type="text" name="PostalCode" id="PostalCode"
                            maxLength="6" minLength={5}
                            onKeyUp={($event) => limitInput($event)}
                            pattern="^[0-9]*$"
                            required={true}
                            onChange={($event) => userInput($event)}
                            className={shipping[0].UserDetails.PostalCode.isValid ? 'form-valid-field' : ''} />
                        <div className="error-wrapper">
                            {
                                (shipping[0].UserDetails.PostalCode.isEmpty && shipping[0].UserDetails.PostalCode.error) &&
                                <div className="error-message error">
                                    <p>
                                        <span>{translations.CoreKelloggs_FieldRequiredError}</span>
                                    </p>
                                </div>
                            }
                            {
                                !shipping[0].UserDetails.PostalCode.isValid && !shipping[0].UserDetails.PostalCode.isEmpty && <div className="error-message error"
                                >
                                    <p>
                                        <span>{translations.CoreKelloggs_KpsPostcodeError}</span>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    {/* <!-- end: postal code form-control -->
                    
                    <!-- Email Address form-control --> */}
                    <div id="shipping-email" className="form-control form-control--left">
                        <label htmlFor="Email">{translations.CoreKelloggs_KpsEmail}</label>

                        <input type="email" name="Email" id="Email" maxLength="100"
                            required={true}
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                            onChange={($event) => userInput($event)}
                            className={shipping[0].UserDetails.Email.isValid ? 'form-valid-field' : ''} />
                        <div className="error-wrapper">
                            {
                                (shipping[0].UserDetails.Email.isEmpty && shipping[0].UserDetails.Email.error) &&
                                <div className="error-message error">
                                    <p>
                                        <span>{translations.CoreKelloggs_FieldRequiredError}</span>
                                    </p>
                                </div>
                            }
                            {
                                !shipping[0].UserDetails.Email.isValid && !shipping[0].UserDetails.Email.isEmpty && <div className="error-message error"
                                >
                                    <p>
                                        <span>{translations.CoreKelloggs_KpsConfirmEmailError}</span>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>

                    {/* <!-- phone number --> */}
                    <div id="shipping-phone" className="form-control form-control--right">
                        <label htmlFor="Phone">{translations.CoreKelloggs_KpsMobileNumber}</label>

                        <input type="tel" name="Phone" minLength="10" maxLength="10" id="Phone"
                            required={true} pattern="^[0-9]*$"
                            onKeyDown={($event) => onlyNumbers($event)}
                            onChange={($event) => userInput($event)}
                            className={shipping[0].UserDetails.Phone.isValid ? 'form-valid-field' : ''} />
                        <div className="error-wrapper">
                            {
                                (shipping[0].UserDetails.Phone.isEmpty && shipping[0].UserDetails.Phone.error) &&
                                <div className="error-message error">
                                    <p>
                                        <span>{translations.CoreKelloggs_FieldRequiredError}</span>
                                    </p>
                                </div>
                            }
                            {
                                !shipping[0].UserDetails.Phone.isValid && !shipping[0].UserDetails.Phone.isEmpty && <div className="error-message error"
                                >
                                    <p>
                                        <span>{translations.CoreKelloggs_KpsMobileNumberError}</span>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    {/* <!-- end: select country form-control -->
             
                    <!-- select country form-control --> */}
                    <div id="shipping-selectcountry" className="form-control form-control--left">
                        <label htmlFor="Country">{translations.CoreKelloggs_KpsCountry}</label>

                        <input type="radio" className='country-selector'
                            required={true} value='ru' name='Country'
                            id='russiaCountry'
                            onChange={() => setRadioBtnCntry(!radioBtnCntry)} />
                        <label htmlFor='russiaCountry'>РОССИЯ</label>
                        {/* <div className="error-wrapper"></div> */}
                    </div>
                    {/* <!-- end: select country form-control -->
                    
                    <!-- checkboxes start here--> */}
                    <div className="termsAndCond">
                        <input type="checkbox" id="KSTL-Registration-TermsandConditions"
                            name="TermsandConditions"
                            required={true}
                            checked={tncCheckbox}
                            onChange={() => setTncCheckbox(!tncCheckbox)}
                        />
                        <div className="error-wrapper">
                            {
                                (shipping[0].UserDetails.TermsandConditions.isEmpty && shipping[0].UserDetails.TermsandConditions.error) &&
                                <div className="error-message error">
                                    <p>
                                        <span>{translations.CoreKelloggs_FieldRequiredError}</span>
                                    </p>
                                </div>
                            }
                        </div>
                        <label htmlFor="KSTL-Registration-TermsandConditions" tabIndex="0"
                            onKeyUp={($event) => oncheck($event)}
                            className={tncCheckbox ? 'checkbox checked' : 'checkbox'}>
                        </label>
                        <label htmlFor="KSTL-Registration-TermsandConditions" className="checkbox-text">
                            <span>{translations.ShippingDetailsTncCheckBox}</span>
                            <a href='/ru_RU/TERMS' target="_blank">{translations.ShippingDetailsTncLink}</a>
                        </label>
                    </div>
                    <div className="receiveInfo">
                        <input type="checkbox" id="KSTL-Registration-subscription6028_EM"
                            name="subscription6028_EM" checked={privacyCheckbox}
                            onChange={() => setPrivacyCheckbox(!privacyCheckbox)} />
                        <label htmlFor="KSTL-Registration-subscription6028_EM" tabIndex="0"
                            onKeyUp={($event) => oncheck($event, shipping[0].UserDetails.subscription6028_EM)}
                            className={privacyCheckbox ? 'checkbox checked' : 'checkbox'}></label>
                        <label htmlFor="KSTL-Registration-subscription6028_EM"
                            className="checkbox-text">{translations.CoreKelloggs_KpsSubscriptionCheckbox}</label>
                    </div>
                    {/* <!-- checkboxes end here--> */}

                    {/* <!-- Recaptcha Starts here--> */}
                    <div className="form-control captcha-box">
                        <div id="g-recaptcha" className="g-recaptcha" sitekey="6LeRF1scAAAAAB-yBjJMrzLYSBqNAGjkf2PokFq1"></div>
                    </div>
                    {/* <!-- Recaptcha Ends here--> */}

                    {/* <!-- submit form --> */}
                    <div className="form-control cta-container">
                        {/*<!-- send form - button --> */}
                        <button type="submit"
                            disabled={ (shipping[0].UserDetails.FirstName.isEmpty ? true : shipping[0].UserDetails.FirstName.error ) || (shipping[0].UserDetails.Email.isEmpty ? true : shipping[0].UserDetails.Email.error ) || (shipping[0].UserDetails.LastName.isEmpty ? true : shipping[0].UserDetails.LastName.error) || (shipping[0].UserDetails.Address1.isEmpty ? true : shipping[0].UserDetails.Address1.error) || (shipping[0].UserDetails.Address2.isEmpty ? true : shipping[0].UserDetails.Address2.error) || (shipping[0].UserDetails.City.isEmpty ? true : shipping[0].UserDetails.City.error) || (shipping[0].UserDetails.PostalCode.isEmpty ? true : shipping[0].UserDetails.PostalCode.error) || (shipping[0].UserDetails.Phone.isEmpty ? true : shipping[0].UserDetails.Phone.error) || !tncCheckbox || !radioBtnCntry || !shipping[0].UserDetails.validateCaptcha}
                            onClick={($event) => setOrder($event)}
                            className={((shipping[0].UserDetails.FirstName.isEmpty ? true : shipping[0].UserDetails.FirstName.error ) || (shipping[0].UserDetails.Email.isEmpty ? true : shipping[0].UserDetails.Email.error ) || (shipping[0].UserDetails.LastName.isEmpty ? true : shipping[0].UserDetails.LastName.error) || (shipping[0].UserDetails.Address1.isEmpty ? true : shipping[0].UserDetails.Address1.error) || (shipping[0].UserDetails.Address2.isEmpty ? true : shipping[0].UserDetails.Address2.error) || (shipping[0].UserDetails.City.isEmpty ? true : shipping[0].UserDetails.City.error) || (shipping[0].UserDetails.PostalCode.isEmpty ? true : shipping[0].UserDetails.PostalCode.error) || (shipping[0].UserDetails.Phone.isEmpty ? true : shipping[0].UserDetails.Phone.error) || !tncCheckbox || !radioBtnCntry || !shipping[0].UserDetails.validateCaptcha) ? 'inactive' : ''}
                            tabIndex="0">
                            <span className={shipping[0].UserDetails.processing ? "ng-binding processing" : "ng-binding"}><i data-ng-activity-indicator="Small"
                                skip-ng-show="yes"
                                className="ng-isolate-scope"><span className="ai-small ai-indicator ai-white-spin"></span></i></span>
                            <span className="btn-copy">{translations.CoreKelloggs_KpsSubmitButton}</span>

                        </button>
                        {/* <!-- end: send form - button --> */}
                    </div>
                </form>
            </section >
        </>
    )
}

export default Shipping;