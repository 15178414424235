import React, { useContext, useState } from 'react';
import { locale, MetaTags } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';

const Terms = () => {
    const { translations } = useContext(JsonContext);

    document.querySelector('body').setAttribute('pagename', 'TERMS');

    let currentlang = locale.toLowerCase(),
        defaultTerms,
        clicked_country;
    // defaultTerms=translations.Tnc;
    switch (currentlang) {
        case 'ro_ro':
            defaultTerms = translations.TncRomania;
            clicked_country = 'Romania';
            break;
    //     // case 'fr_be':
    //     // defaultTerms=translations.TncBelgium;
    //     // clicked_country='Belgium';
    //     // break;
        default:
            defaultTerms = translations.Tnc;
    }
    const [ tncContent, setTncContent ] = useState(defaultTerms);
    const [ clickedCountry, setClickedCountry ] = useState(clicked_country?clicked_country:'');
    const show_hide = function (key) {
        clicked_country = key.trim();
        setClickedCountry(clicked_country);
        // tncContent = translations['Tnc' + key];
        setTncContent(translations['Tnc' + key]);
        /*  if(clickedCountry  === 'Luxembourgh'){
              tncContent=translations['TncLuxembourgh'];
          } else if(clickedCountry === 'Belgium') {
              tncContent=translations['TncBelgium'];
          } else if(clickedCountry === 'Malta') {
              tncContent=translations['TncMalta'];
          } else if(clickedCountry === 'Iceland') {
              tncContent=translations['TncIceland'];
          } else{
              tncContent=translations['Tnc'+key];
          }*/
    };
    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_TermsPageTitle + ' | ' + translations.CampaignName }
                description={translations.MetaContent}
                keywords=''
            />
            <section className="popup active terms-page">
                <div id="TERMS" className="popup-content active">
                    {
                        (currentlang != 'el_gr' && currentlang != 'az_az') && <h1>{translations.CoreKelloggs_FooterTerms}</h1>
                    }
                    {
                        currentlang == 'el_gr' && <h1 >ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ</h1>
                    }
                     {
                        currentlang == 'ro_ro' && <ol className="extra-country-nav">
                            {
                                currentlang == 'ro_ro' && <li className={clickedCountry !== 'Romania' ? 'active inactive' : 'active'} onClick={() => show_hide('Romania')}>
                                    <a href='javascript:void(0);'>{translations.LabelRomania}</a></li>
        
                            }
                            {
                                currentlang == 'ro_ro' && <li  onClick={()=>show_hide('Moldova')}
                                    className={clickedCountry === 'Moldova' ? 'active': ' '}
                                > <a href='javascript:void(0);'>{translations.LabelMoldova}</a></li>
                            }
                            {/* {
                                currentlang == 'en_gr' && <li onClick="show_hide('Iceland')"
                                    className={clickedCountry === 'Iceland' && 'active'}
                                >{translations.LabelIceland}</li>
                            }
                            {
                                currentlang == 'fr_be' && <li onClick="show_hide('Belgium')"
                                    className={'active ' + clickedCountry !== 'Belgium' && 'inactive'}
                                >{translations.LabelBelgium}</li>
                            }
                            {
                                currentlang == 'en_gr' && <li onClick="show_hide('Luxembourg')"
                                    className={clickedCountry === 'Luxembourg' && 'active'}
                                >{translations.LabelLuxembourg}</li>
                            } */}
                        </ol>
                    }
                    <p dangerouslySetInnerHTML={{ __html: tncContent }}></p>
                </div>
            </section>
        </>
    )
}

export default Terms;