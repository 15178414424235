import React, { useContext } from 'react';
import Button from '../Partials/Button';
import { MetaTags } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';
const Comingsoon = () => {
    const { translations } = useContext(JsonContext);

    sessionStorage.removeItem('createPageState');
    document.querySelector('body').setAttribute('pagename', 'Comingsoon');

    return (
        <>
            <MetaTags
                title={translations.CoreKelloggs_ComingSoonPageTitle + ' | ' + translations.CampaignName }
                description={translations.MetaContent}
                keywords=''
            />
            <section className="comingsoon-page">
                <div className="content col-one">
                    <h1>{translations.ComingSoonHeading}</h1>
                    <p dangerouslySetInnerHTML={{ __html: translations.ComingSoonDescription }}></p>
                    <Button buttonType='holding'/>
                </div>
            </section>
        </>
    )
}

export default Comingsoon;