import React, { useContext } from 'react';
import { MetaTags, LoadGRM } from '../Utility/Global';
import JsonContext from '../Utility/JsonProvider';

const Forgotpassword = () => {
    const { translations } = useContext(JsonContext);

    document.querySelector('body').setAttribute('pagename', 'Forgotpassword');

    if ((window.location.href).indexOf('resetpassword') > 0 || (window.location.href).indexOf('unlockaccount') > 0) {
        LoadGRM('reset', function () { });
    }
    else {
        LoadGRM('forgot', function () { });
    }

    return (
        <>
            <MetaTags
                title={translations.CampaignName }
                description={translations.MetaContent}
                keywords=''
            />
            <section className="forgotpassword-page login-page">
                <div id="reg-container" className="register-form"></div>
            </section>
        </>
    )
}

export default Forgotpassword;